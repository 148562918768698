"use client";

import {
  ACCOUNT_FIELD_NAME,
  BUSINESS_ID_FACTORY,
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  BUSINESS_SECTOR_LAUNDRY,
  isPaymentTypeSupported,
  TEMPLATE_ENVIRONMENT,
  toDisplayName,
} from "@easybiz/utils";
import { createContext, useContext, useMemo } from "react";
import { useUserBusinessCode, useUserCurrency, useUserRealmId } from "./UserContext";

const RealmContext = createContext();

export function RealmProvider({ children, realm }) {
  const businessCode = useUserBusinessCode();
  const currency = useUserCurrency();

  const context = useMemo(() => {
    const services = (realm?.services || [])
      .map(({ codes, priceLists, agent, agents, ...service }) => {
        const priceList =
          businessCode &&
          priceLists?.find((list) => list.serviceId === service.id && list.codes?.includes(businessCode));

        if (
          !businessCode ||
          (Array.isArray(codes) &&
            codes.includes(businessCode) &&
            [BUSINESS_SECTOR_LAUNDRY, BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_ALTERATION].indexOf(
              service.sector
            ) >= 0)
        ) {
          const supplierId = (agents && agents[businessCode]) || agent;

          return {
            ...service,
            ...(supplierId && { supplierId }),
            ...(Array.isArray(codes) && { codes }),
            ...(priceList && {
              pricing: priceList.version || null,
            }),
          };
        }
      })
      .filter((service) => service)
      .sort((a, b) => {
        const useCount = (b.codes?.length || 0) - (a.codes?.length || 0);

        if (useCount === 0) {
          return a.title.localeCompare(b.title);
        } else {
          return useCount;
        }
      });

    return {
      ...realm,
      stripePaymentMethods: Object.keys(realm?.capabilities || {})
        .map((key) => {
          if (key.endsWith("_payments")) {
            const id = key.split("_payments")[0];
            if (isPaymentTypeSupported(id, currency)) {
              return id;
            }
          }
        })
        .filter(Boolean)
        .sort()
        .map((id) => ({ id, status: realm.capabilities[`${id}_payments`] })),
      services,
      supplierIds: services
        .map((service) => service.supplierId)
        .filter((id) => id)
        .filter((id, index, self) => self.indexOf(id) === index),
      realmLoaded: Boolean(realm),
    };
  }, [realm, businessCode, currency]);

  return <RealmContext.Provider value={context}>{children}</RealmContext.Provider>;
}

export const useRealmIsLoaded = () => useContext(RealmContext).realmLoaded;
export const useRealmContext = () => useContext(RealmContext);
export const useRealmSubscriptionStatus = () => useContext(RealmContext).subscription?.status;
export const useRealmServices = (businessCode) => {
  const { services, priceLists } = useContext(RealmContext);

  return businessCode
    ? services
        ?.filter((service) => service.codes?.includes(businessCode))
        .map((service) => {
          const priceList = priceLists?.find(
            (list) => list.serviceId === service.id && list.codes?.includes(businessCode)
          );

          return priceList
            ? {
                ...service,
                pricing: priceList.version || null,
                priceListId: priceList.id,
              }
            : service;
        })
    : services;
};
export const useRealmCompanies = () => useContext(RealmContext).companys;
export const useRealmPriceLists = () => useContext(RealmContext).priceLists;
export const useRealmSupplierIds = () => useContext(RealmContext).supplierIds;
export const useRealmStripePaymentMethods = () => useContext(RealmContext).stripePaymentMethods;
export const useRealmCollectionPoints = () => useContext(RealmContext).collectionPoints;
export const useRealmRecyclables = () => useContext(RealmContext).recyclables;
export const useRealmServiceSectors = () => useContext(RealmContext).sectors;
export const useRealmToDisplayName = () => {
  const context = useContext(RealmContext);

  return (nameKey) => {
    if (nameKey === BUSINESS_ID_FACTORY) {
      return "Plant";
    } else {
      return toDisplayName(nameKey, context);
    }
  };
};
export const useRealmIsTemplateEnv = () => {
  const realmId = useUserRealmId();
  return realmId === TEMPLATE_ENVIRONMENT;
};

// TODO: IMPROVE
export const useRealmJobPollServiceIds = () => useContext(RealmContext).publicServices;
export const useRealmDefaultDepartment = () => {
  const departments = useContext(RealmContext).departments;
  return (Array.isArray(departments) && departments[0]) || "OTHERS";
};
export const useRealmNewBookingEnabled = () => useContext(RealmContext).newBookingEnabled;
export const useRealmPOSOnlinePaymentMethods = () => useContext(RealmContext).posStripeMethods;
export const useRealmIsServiceSlotReady = (serviceId) => {
  const { services } = useContext(RealmContext);
  return Boolean(services?.find((service) => service.slot && service.id === serviceId));
};
export const useRealmPOSSettings = () => useContext(RealmContext).posSettings || {};
export const useRealmLogisticSettings = () => useContext(RealmContext).logisticSettings || {};
export const useRealmProductionSettings = () => useContext(RealmContext).productionSettings || {};
export const useRealmLabelSettings = () => useContext(RealmContext).labelSettings || {};
export const useRealmMemberSettings = () => useContext(RealmContext).memberSettings || {};
