"use client";

import { useAdminDeviceRegister } from "@easybiz/api-admin";
import {
  useClientInstallId,
  useClientLocation,
  useClientPushToken,
  useClientType,
  useFeedbackToast,
  useLicenseClientAuthDeviceId,
  useLicenseClientAuthTimestamp,
  useUser,
  useUserAuthTime,
  useUserRealmId,
  useUserSignOut,
  useUserUID,
} from "@easybiz/shell";
import dayjs from "dayjs";
import { useEffect } from "react";

function DeviceRegister({ getDeviceInfo, onRegistered, betaRelease }) {
  const uid = useUserUID();
  const realmId = useUserRealmId();
  const onSignOut = useUserSignOut();
  const multiStation = useUser()?.multiStation;
  const lastAuthTimestamp = useLicenseClientAuthTimestamp();
  const lastAuthDeviceId = useLicenseClientAuthDeviceId();
  const authTime = useUserAuthTime();
  const client = useClientType();
  const installId = useClientInstallId();
  const pushToken = useClientPushToken();
  const location = useClientLocation();
  const toast = useFeedbackToast();
  const multiStationRetry = multiStation && location?.failed ? location.timestamp : null;
  const { onSubmit, response, onClear } = useAdminDeviceRegister((succeed, response) => {
    if (succeed) {
      onRegistered(response);
    }
  });

  useEffect(() => {
    if (response?.errorCode) {
      onSignOut().then(() =>
        toast.info({
          title: "Login session expired",
          dialog: true,
        })
      );
      onClear();
    }
  }, [Boolean(response?.errorCode)]);

  useEffect(() => {
    if (installId && uid && realmId) {
      // Init device register (Setup permissions)
      onSubmit({ ...getDeviceInfo(), installId, pushToken, ...(location && { location }), betaRelease });
    }
  }, [uid, realmId, installId, pushToken, multiStationRetry, betaRelease]);

  useEffect(() => {
    if (
      multiStation === false &&
      client &&
      installId &&
      authTime &&
      lastAuthTimestamp &&
      lastAuthTimestamp > authTime
    ) {
      if (lastAuthDeviceId !== installId) {
        // Sign out
        onSignOut().then(() =>
          toast.info({
            title: "Login session expired",
            description: `Your account has been logged in another device ID:${lastAuthDeviceId} at ${dayjs
              .unix(lastAuthTimestamp)
              .format("HH:mm DD MMM YYYY")}, you can enable device whitelist to limit device access to your system.`,
            dialog: true,
          })
        );
      } else {
        onSubmit({ ...getDeviceInfo(), installId, pushToken });
      }
    }
  }, [multiStation, client, installId, authTime, lastAuthTimestamp, lastAuthDeviceId]);

  return null;
}

export default DeviceRegister;
