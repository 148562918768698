import { PERMISSION_LEVEL_FULL, PERMISSION_LEVEL_TIME_LIMITED, PERMISSION_TIME_SAME_DAY } from "@easybiz/constants";
import dayjs from "dayjs";

export default function isDayLimitedNew(date, permission) {
  const today = dayjs();

  if (permission?.level === PERMISSION_LEVEL_FULL) {
    return false;
  } else if (permission?.level === PERMISSION_LEVEL_TIME_LIMITED) {
    if (permission.timeWindow === PERMISSION_TIME_SAME_DAY) {
      return today.format("YYYY-MM-DD") !== date;
    } else {
      return date < today.subtract(permission.days, "days").format("YYYY-MM-DD");
    }
  } else {
    return true;
  }
}
