"use client";

import { MASTER_LICENSE_ID } from "@easybiz/constants";
import {
  AccessProvider,
  ActionProvider,
  AsyncUploadProvider,
  ChatProvider,
  FeedbackProvider,
  KeyboardProvider,
  KVStorageProvider,
  LicenseProvider,
  PrintingProvider,
  RealmProvider,
  useClientCustomerScreenSetter,
  useClientHasNetwork,
  useClientInstallId,
  useClientInstallIdSetter,
  useClientRegisterDeviceSetter,
  useClientStateSetter,
  useClientType,
  UserProvider,
} from "@easybiz/shell";
import { ADMIN_ACTION_DEVICE_SIGN_OUT, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useCallback, useEffect, useState } from "react";
import ChatConversationMonitor from "./ChatConversationMonitor";
import ChatUnreadMonitor from "./ChatUnreadMonitor";
import ClientDateMonitor from "./ClientDateMonitor";
import DeviceRegister from "./DeviceRegister";

export default function WorkflowApp({
  app,
  splashScreen,
  children,
  localStorage,
  uploadStorage,
  getDeviceInfo,
  toaster,
  // Printing
  ESCEncoder,
  usbIO,
  bluetoothIO,
  connectPrinter,
  // Firebase: Installations
  getId,
  getInstallations,
  onIdChange,
  // Firebase: Auth
  onIdTokenChanged,
  signInWithCustomToken,
  signOut,
  getAuth,
  // Firebase: Firestore
  onSnapshot,
  runTransaction,
  arrayUnion,
  doc,
  getFirestore,
  deleteDoc,
  // Firebase: Functions
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
}) {
  const client = useClientType();
  const installId = useClientInstallId();
  const hasNetwork = useClientHasNetwork();
  const setInstallId = useClientInstallIdSetter();
  const setAppState = useClientStateSetter();
  const setRegisterDevice = useClientRegisterDeviceSetter();
  const setConnectDevice = useClientCustomerScreenSetter();
  const [user, setUser] = useState();
  const [realm, setRealm] = useState();
  const [license, setLicense] = useState();
  const realmId = user?.realmId;
  const pendingSignIn = Boolean(user && !user.uid);
  const signedIn = Boolean(user?.uid);
  const licenseId = user?.licenseId;
  const customerScreenId = user?.connectDeviceId || installId;

  useEffect(() => {
    if (!installId) {
      getId(getInstallations()).then(setInstallId);

      return onIdChange(getInstallations(), setInstallId);
    }
  }, [installId]);

  useEffect(() => {
    return onIdTokenChanged(getAuth(), async (user) => {
      if (user) {
        // Sign in
        const { claims } = await user.getIdTokenResult();

        if (claims.installId) {
          setInstallId(claims.installId);
        }

        setUser({
          ...claims.realmConfig, // TODO: REMOVE
          ...(claims.easybussuper && { easybussuper: true, permissions: [] }),
          ...(claims.currency && { currency: claims.currency }),
          ...(claims.countryCode && { countryCode: claims.countryCode }),
          ...(claims.dialCode && { dialCode: claims.dialCode }),
          ...(claims.city && { city: claims.city }),
          ...(claims.geoCenter && { geoCenter: claims.geoCenter }),
          ...(claims.stripeAccount && { stripeAccount: claims.stripeAccount }),
          ...(claims.isTestMode && { isTestMode: claims.isTestMode }),
          ...(claims.installId && { installId: claims.installId }),
          ...(claims.businessCode && { businessCode: claims.businessCode }),
          ...(typeof claims.multiStation === "boolean" && { multiStation: claims.multiStation }),
          ...(claims.storeLocation && { storeLocation: claims.storeLocation }),
          ...(claims.clients && { clients: claims.clients }),
          ...(claims.realmMaster && { realmMaster: true }),
          ...(claims.connectDeviceId && { connectDeviceId: claims.connectDeviceId }),
          ...(claims.staffId && {
            checkInStaff: {
              id: claims.staffId,
              name: claims.staffName || "",
              ...(claims.clockInTime && { clockInTime: claims.clockInTime }),
            },
          }),
          ...(claims.permissions && { permissions: claims.permissions }),
          ...(claims.ebAccess && { debugMode: claims.ebAccess }),
          ...(claims.realmId && { realmId: claims.realmId }),
          ...(claims.trial && { trial: claims.trial, realmId: null }), // For trial user, ignore the current realmId (Case like the user already registered under other realms as workflow accounts)
          ...(claims.auth_time && { authTime: claims.auth_time }),
          ...(user.photoURL && { avatarUrl: user.photoURL }),
          licenseId: claims.realmMaster ? MASTER_LICENSE_ID : claims.businessCode || claims.seatId,
          name: user.displayName || null,
          email: user.email || null,
          emailVerified: user.emailVerified,
          uid: user.uid,
        });
      } else {
        setUser({});
      }
    });
  }, []);

  // Monitor realm
  useEffect(() => {
    if (realmId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}`), (snapshot) =>
        setRealm({ ...snapshot.data(), realmId: snapshot.id })
      );
    }
  }, [realmId]);

  // Monitor license
  useEffect(() => {
    if (realmId && licenseId) {
      return onSnapshot(doc(getFirestore(), `billings/${realmId}/licences/${licenseId}`), (snapshot) =>
        setLicense(snapshot.data() || {})
      );
    }
  }, [realmId, licenseId]);

  // Monitor registered device
  useEffect(() => {
    if (installId && client && realmId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/devices/${client}/installs/${installId}`),
        setRegisterDevice
      );
    }
  }, [installId, realmId, client]);

  // Monitor connected device (POS secondary screen)
  useEffect(() => {
    if (realmId && customerScreenId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/customer_screens/${customerScreenId}`),
        setConnectDevice
      );
    }
  }, [realmId, customerScreenId]);

  // Monitor unauthorized device (for admin controlled auto sign-in)
  useEffect(() => {
    if (installId && pendingSignIn) {
      return onSnapshot(doc(getFirestore(), `devices/${installId}`), (device) => {
        if (device.get("token")) {
          signInWithCustomToken(getAuth(), device.get("token")).then(() => {
            deleteDoc(device.ref);
          });
        }
      });
    }
  }, [installId, pendingSignIn]);

  const callable = useCallback((params, functionName) => {
    const functions = getFunctions();

    // if (process.env.NODE_ENV === "development") {
    //   connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    // }

    let isStaging;

    if (typeof window !== "undefined") {
      isStaging = window.location.hostname.includes("staging");
    }

    return httpsCallable(
      functions,
      functionName && typeof functionName === "string"
        ? functionName
        : process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" || isStaging
        ? "stagingOperationCreate"
        : "operationCreate"
    )(params);
  }, []);

  const onRegistered = useCallback(({ signInToken, notice, intercomUser }) => {
    if (signInToken) {
      signInWithCustomToken(getAuth(), signInToken);
    }

    if (notice) {
      setAppState({ notice });
    }

    // TODO: REMOVE INTERCOM
    if (intercomUser && typeof window !== "undefined" && window.Intercom) {
      window.Intercom("boot", {
        ...intercomUser,
        app_id: "vohrcihc",
        custom_launcher_selector: `#intercomLauncher`,
        hide_default_launcher: true,
      });
    }
  }, []);

  const onSignOut = useCallback(() => {
    if (signedIn) {
      return new Promise((resolve) => {
        if (hasNetwork) {
          callable({
            group: OPERATION_GROUP_ADMIN,
            type: ADMIN_ACTION_DEVICE_SIGN_OUT,
            client,
          })
            .then(resolve)
            .catch(resolve);
        } else {
          resolve();
        }
      }).then(() => signOut(getAuth()));
    }
  }, [signedIn]);

  return (
    <LicenseProvider license={license}>
      <UserProvider user={user} setUser={setUser} onSignOut={onSignOut}>
        <RealmProvider realm={realm}>
          <AccessProvider>
            <ActionProvider
              callable={callable}
              firestore={{
                onSnapshot,
                runTransaction,
                arrayUnion,
                doc,
                getFirestore,
                deleteDoc,
              }}
            >
              <PrintingProvider
                ESCEncoder={ESCEncoder}
                usbIO={usbIO}
                bluetoothIO={bluetoothIO}
                connectPrinter={connectPrinter}
              >
                <FeedbackProvider toaster={toaster}>
                  <AsyncUploadProvider uploadStorage={uploadStorage}>
                    <KVStorageProvider localStorage={localStorage}>
                      <KeyboardProvider>
                        <ChatProvider>
                          {/* app is the main application, pages from NextJS or Screens from Native navigation */}
                          {splashScreen && (!user || (user?.uid && !user.permissions && !user.trial))
                            ? splashScreen
                            : app}
                          {/* Children are additional components from web/native Apps */}
                          {children}
                          <ChatConversationMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                          <ChatUnreadMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                        </ChatProvider>
                      </KeyboardProvider>
                    </KVStorageProvider>
                  </AsyncUploadProvider>

                  <DeviceRegister
                    getDeviceInfo={getDeviceInfo}
                    onRegistered={onRegistered}
                    betaRelease={Boolean(process.env.NEXT_PUBLIC_partial_release)}
                  />
                  <ClientDateMonitor />
                </FeedbackProvider>
              </PrintingProvider>
            </ActionProvider>
          </AccessProvider>
        </RealmProvider>
      </UserProvider>
    </LicenseProvider>
  );
}
